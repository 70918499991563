
export default function ServicesComp({text, title, img}){
    return(
        <>
            <div className='h-96 w-72 bg-customBlue-bedge p-5 flex flex-col gap-2 rounded-2xl  hover:translate-y-2'>
                <img src={img} alt='' className='rounded '/>
                <h3 className='font-bold  text-center '>{title}</h3>
                <p className=' text-center h-100% overflow-scroll'>{text}</p>
            </div>
        </>
    )
}