
import one from './images/one.webp'
import ServicesComp from "./services_comp";
export default function Services(){
    return(
        <>

            <div className='w-100vw mt-10 flex flex-col flex-wrap'>
                <h1 className='text-center text-3xl capitalize font-bold'>Our Services</h1>
                <div className=' md:mx-36 flex flex-wrap  my-10 justify-around gap-10'>
                    <ServicesComp title={"Industrial Projects"} img={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKiyNi3EE0KDkShY5cveBeXd6U4ZFNCPVSqg&s'}
                                  text={'We specialize in a wide range of industrial projects and maintenance services tailored to meet the needs of various sectors within the civil engineering field.' +
                        '\n'}/>
                    <ServicesComp title={"Construction of Warehouses"} img={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkxdaH5C0imv_4A-GLHykqMWIhWIe9hXaq_w&s'}
                                  text={'Our team excels in the design and construction of state-of-the-art warehouse facilities tailored to meet the specific needs of your business'}/>
                    <ServicesComp title={"Construction of Industrial Sheds"} img={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxZlNhQsUzHPV-2PG41FUaqgyeHMXFbj-xww&s'}
                                  text={'We offer comprehensive services in the construction of industrial sheds, designed to provide durable and efficient solutions for a wide range of industrial applications. '}/>
                    <ServicesComp title={"Industrial building construction & maintenance services "} img={one} text={'Our expertise in industrial building construction and maintenance services ensures that your facilities are built to the highest standards and maintained for long-term operational efficiency. '}/>
                    <ServicesComp title={"Industrial Road Construction"} img={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHL85QlVkulmfsrpPF-5t0L8MhM-LXYgU7Ag&s'}
                                  text={'We specialize in the construction of industrial roads designed to support heavy-duty usage and ensure efficient transportation within industrial zones. '}/>
                    <ServicesComp title={"Platform Building"} img={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpnlIl4hkmYGiuK-C1ibdpVfpI5TNEXoTanw&s'}
                                  text={'We offer specialized services in the construction of platforms for various industrial applications, ensuring durable and functional solutions tailored to your specific needs.'}/>
                </div>

            </div>
        </>
    )
}