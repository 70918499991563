
import ServicesComp from "./services_comp";
export default function Clients(){
    return(
        <>

            <div className='w-100vw mt-10 flex flex-col flex-wrap'>
                <h1 className='text-center text-3xl capitalize font-bold'>Our Clients</h1>
                <div className='flex flex-wrap md:mx-36 justify-around my-10  gap-10'>
                    <ServicesComp title={""} img={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYAInRaINhZgM3NCfKv5jjlbfiQKxNRyHPtg&s'} text={''}/>
                    <ServicesComp title={""} img={'https://kikkidu.com/wp-content/uploads/2011/04/bajajElectricals.jpg'} text={''}/>
                    <ServicesComp title={""} img={'https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/ITC_Limited_Logo.svg/1200px-ITC_Limited_Logo.svg.png'} text={''}/>
                </div>

            </div>
        </>
    )
}