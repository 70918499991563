
export default function Intro(){
    const link = 'https://www.asce.org/-/media/asce-images-and-files/publications-and-news/civil-engineering-magazine/images/2022/01-january/cep-feature-ce-clubs-are-designed-for-success/62-ce-clubs.jpg'
    return(
        <>
        <div className="h-[700px] w-100vw bg-black text-white flex flex-wrap text-center gap-10  items-center justify-center flex-col" style={{backgroundImage:`url('${link}')`,backgroundSize:"cover"}}>
            <h1 className= 'text-6xl font-bold  text-black font-abril-fatface '>Timeline Enterprises</h1>
            <div className=' flex flex-wrap items-center justify-center flex-col'>
                <div>
                <h1 className='text-3xl font-bold text-black font-abril-fatface'>We Build Great Projects</h1>
                    </div>
                <p className='w-[400px] mt-5 text-2xl text-black' >Far far away, behind the word mountains, far from the countries
                    Vokalia and Consonantia, there live the blind texts. Separated
                    they live in Bookmarksgrove.</p>
            </div>
        </div>
        </>
    )
}