export default function Footer(){
    return(
        <>
            <footer className="bg-gray-800 text-white py-8">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap justify-between">
                        <div className="w-full md:w-1/3 mb-4 md:mb-0">
                            <h2 className="text-lg font-semibold">About Us</h2>
                            <p className="text-sm w-[350px]">
                                We take this privilege to introduce ourselves as Civil Engineers &Contractors for executing
civil engineering projects in residential, Industrial &infrastructure works. The firm has begun its work in construction industry as Civil Contractors by the name of M/s. TIME LINE
Enterprises & finished some
variety of projects, since 02 years.
                            </p>
                        </div>
                        <div className="w-full md:w-1/3 mb-4 md:mb-0">
                            <h2 className="text-lg font-semibold">Our Services</h2>
                            <ul className="list-none">
                                <li className="text-sm hover:underline">Industrial building construction&
                                    maintains</li>
                                <li className="text-sm hover:underline">Construction of Warehouses</li>
                                <li className="text-sm hover:underline">Construction of Industrial Sheds
                                </li>
                                <li className="text-sm hover:underline">Industrial building construction &
                                    maintenance services </li>
                                <li className="text-sm hover:underline">Industrial Road Construction
                                </li>
                                <li>Platform Building
                                </li>
                            </ul>
                        </div>
                        <div className="w-full md:w-1/3 mb-4 md:mb-0">
                            <h2 className="text-lg font-semibold">Contact Us</h2>
                            <div className="flex space-x-4">
                                <ul className="list-none">
                                    {/*<li> <img src={''} /> </li>*/}
                                    <li className="text-sm hover:underline">+91 7448174407</li>
                                    <li className="text-sm hover:underline">Timelineenterprises95@gmail.com
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 text-center text-sm">
                        <p>&copy; 2024 Timeline Enterprices. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </>
    )
}