import Home from './pages/home'
function App() {
  return (
      <div className="App">
          <Home/>
      </div>
  );
}

export default App;
