import React from "react";
import Intro from '../components/intro'
import Services from "../components/services";
import Clients from "../components/clients";
import Footer from "../components/footer";
export default function Home(){
    return (
        <>
            <Intro/>
            <Services/>
            <Clients/>
            <Footer/>
        </>
    )
}